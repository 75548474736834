// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/components/Notification.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/components/Notification.tsx");
  import.meta.hot.lastModified = "1741617201000";
}
// REMIX HMR END

import React, { useState } from 'react';
import { Avatar, Link } from '~ui';
import { HistoryEntryType, NotificationType, RsvMembershipStatus } from '~admin/generated/graphql';
import { prettyDateFormat } from '~common/utils/dateHelper';
import { config as orderConfig } from '~admin/modules/order/config';
import { config as customerConfig } from '~admin/modules/customer/config';
import { config as agreementConfig } from '~admin/modules/agreement/config';
import { config as membershipConfig } from '~admin/modules/membership/config';
import { config as classConfig } from '~admin/modules/clazz/config';
import { notificationTitle } from '../config';
import { NotificationIcon } from './NotificationIcon';
import { formatPrice } from '~common/utils/format/priceFormat';
export const dataTranslate = entry => {
  switch (entry.type) {
    case HistoryEntryType.ParticipantOnboardFailed:
    case HistoryEntryType.ParticipantOnboard:
      {
        const {
          orderlineId,
          customerId,
          customerFirstName,
          customerLastName,
          clazzId,
          clazz,
          groupId,
          group,
          error
        } = entry.data ?? {};
        return <span className="block">
          <span className="block">
            Message:{' '}
            <span className="font-light text-error-500 dark:text-error-300">
              {error ? 'Failed to onboard in' : 'Onboarded in'}{' '}
              {`${group} group for ${clazz}`}
            </span>
          </span>
          {error ? <span className="block">
              Error:{' '}
              <span className="font-light text-error-500 dark:text-error-300">
                {error}
              </span>
            </span> : null}
          <div className="flex gap-2">
            {orderlineId ? <span className="block">
                <span className="font-light dark:text-slate-300">
                  <Link to={`${orderConfig.path}/by-orderline/${orderlineId}`}>
                    Order
                  </Link>
                </span>
              </span> : null}
            {error ? <span className="block">
                <span className="font-light dark:text-slate-300">
                  <Link to={`${classConfig.path}/onboard/${clazzId}`}>
                    Onboard
                  </Link>
                </span>
              </span> : null}
          </div>
        </span>;
      }
    case HistoryEntryType.SubscriptionNotification:
      {
        const {
          message,
          valid,
          error,
          orderLineId,
          subscriptionId
        } = entry.data ?? {};
        return <span className="block">
          <span className="block">
            Message:{' '}
            <span className="font-light text-error-500 dark:text-error-300">
              {message}
            </span>
          </span>
          {error ? <span className="block">
              Error:{' '}
              <span className="font-light text-error-500 dark:text-error-300">
                {error}
              </span>
            </span> : null}
          <span className="block">
            Valid:{' '}
            <span className="font-light text-error-500 dark:text-error-300">
              {valid}
            </span>
          </span>
          <span className="block">
            OrderLineId:{' '}
            <span className="font-light dark:text-slate-300">
              {orderLineId}
            </span>
          </span>
          <span className="block">
            SubscriptionId:{' '}
            <span className="font-light dark:text-slate-300">
              {subscriptionId}
            </span>
          </span>
        </span>;
      }
    case HistoryEntryType.SubscriptionError:
      {
        const {
          product,
          orderLineId,
          customerId,
          customerName,
          paymentMethod,
          paymentId,
          action,
          error
        } = entry.data ?? {};
        return <span className="block">
          <span className="block">
            Error:{' '}
            <span className="font-light text-error-500 dark:text-error-300">
              {error}
            </span>
          </span>
          <span className="block">
            PaymentMethod:{' '}
            <span className="font-light dark:text-slate-300">
              {paymentMethod ?? 'Undefined'}
            </span>
          </span>
          <span className="block">
            Product:{' '}
            <span className="font-light dark:text-slate-300">{product}</span>
          </span>
          <span className="block">
            Membership:{' '}
            <span className="font-light dark:text-slate-300">
              <Link to={`${membershipConfig.path}/${customerId}`}>
                {customerName}
              </Link>
            </span>
          </span>
          {action ? <span className="block">
              Action:{' '}
              <span className="font-light dark:text-slate-300">{action}</span>
            </span> : null}

          {paymentId ? <span className="block">
              Payment:{' '}
              <span className="font-light dark:text-slate-300">
                <Link to={`${membershipConfig.path}/${customerId}/orderline/${orderLineId}/payment/${paymentId}`}>
                  {paymentId}
                </Link>
              </span>
            </span> : null}
        </span>;
      }
    case HistoryEntryType.SubscriptionEdit:
      {
        const {
          product,
          orderLineId,
          customerId,
          customerName,
          oldRecurringAmount,
          recurringAmount,
          oldRenewalAmount,
          renewalAmount,
          nextRunDate,
          numLeft,
          note,
          userId
        } = entry.data ?? {};
        return <span className="block">
          <span className="block">
            Product:{' '}
            <span className="font-light dark:text-slate-300">{product}</span>
          </span>
          <span className="block">
            Customer:{' '}
            <span className="font-light dark:text-slate-300">
              <Link to={`${membershipConfig.path}/${customerId}`}>
                {customerName}
              </Link>
            </span>
          </span>
          {note ? <span className="block">
              Note:{' '}
              <span className="font-light dark:text-slate-300">{note}</span>
            </span> : null}
          {oldRecurringAmount && oldRecurringAmount !== recurringAmount ? <span className="block">
              Recurring from:{' '}
              <span className="font-light dark:text-slate-300">
                {formatPrice(oldRecurringAmount)}
              </span>
              , to:{' '}
              <span className="font-light dark:text-slate-300">
                {formatPrice(recurringAmount)}
              </span>
            </span> : null}
          {oldRenewalAmount && oldRenewalAmount !== renewalAmount ? <span className="block">
              Renewal from:{' '}
              <span className="font-light dark:text-slate-300">
                {formatPrice(oldRenewalAmount)}
              </span>
              , to:{' '}
              <span className="font-light dark:text-slate-300">
                {renewalAmount ? formatPrice(renewalAmount) : undefined}
              </span>
            </span> : null}
          {nextRunDate ? <span className="block">
              Next run date:{' '}
              <span className="font-light dark:text-slate-300">
                {prettyDateFormat(nextRunDate)}
              </span>
            </span> : null}
          {typeof numLeft !== 'undefined' ? <span className="block">
              Payments left:{' '}
              <span className="font-light dark:text-slate-300">
                {numLeft === 0 ? 'Auto renew' : numLeft}
              </span>
            </span> : null}
        </span>;
      }
    case HistoryEntryType.SubscriptionChange:
      return <span className="block">
          <span className="block">
            From:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.from}
            </span>
            , to:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.to === RsvMembershipStatus.Producttransition ? entry.data.from === RsvMembershipStatus.Trial ? 'ENROLLMENT' : 'UPGRADE' : entry.data.to}
            </span>
          </span>
          <span className="block font-light dark:text-slate-300">
            <Link to={`${customerConfig.path}/${entry.data.assignedCustomerId}/details`}>
              {entry.data.assignedCustomerFirstName}{' '}
              {entry.data.assignedCustomerLastName}
            </Link>
          </span>
        </span>;
    case HistoryEntryType.MembershipStateTransition:
      return <span className="block">
          <span className="block">
            From:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.from}
            </span>
            , to:{' '}
            <span className="font-light dark:text-slate-300">
              {entry.data.to === RsvMembershipStatus.Producttransition ? 'UPGRADE' : entry.data.to}
            </span>
          </span>
          {entry.data.optionalProductVariantName ? <span className="font-light dark:text-slate-300">
              Product: {entry.data.optionalProductVariantName}
            </span> : null}
        </span>;
    case HistoryEntryType.OrderStateTransition:
    case HistoryEntryType.OrderPaymentTransition:
    case HistoryEntryType.OrderRefundTransition:
    case HistoryEntryType.OrderFulfillmentTransition:
    case HistoryEntryType.ParticipantStateTransition:
      return <span className="block">
          {entry.data.from ? <>
              From:{' '}
              <span className="font-light dark:text-slate-300">
                {entry.data.from}
              </span>
              ,{' '}
            </> : null}
          to:{' '}
          <span className="font-light dark:text-slate-300">
            {entry.data.to}
          </span>
        </span>;
    case HistoryEntryType.CustomerAddressCreated:
    case HistoryEntryType.CustomerAddressUpdated:
    case HistoryEntryType.CustomerAddressDeleted:
      return <span className="block">
          <span className="font-light dark:text-slate-300">
            {entry.data.address}
          </span>
        </span>;
    case HistoryEntryType.OrderCancellation:
      return <span className="block">
          <span className="font-light dark:text-slate-300">
            {entry.data.reason}
          </span>
          , paymentId: {entry.data.paymentId}
        </span>;
    case HistoryEntryType.OrderCouponApplied:
    case HistoryEntryType.OrderCouponRemoved:
      return <span className="block font-light dark:text-slate-300">
          {entry.data.couponCode}
        </span>;
    case HistoryEntryType.OrderNote:
    // TODO integrate into history server side
    case HistoryEntryType.StaffNote:
    case HistoryEntryType.ParticipantNote:
    case HistoryEntryType.ParticipantMessage:
      return <span className="block w-full font-light text-ellipsis dark:text-slate-300">
          {entry.data.note}
        </span>;
    case HistoryEntryType.ParticipantCheckin:
      return <span className="block w-full font-light text-ellipsis dark:text-slate-300">
          Status:{' '}
          {entry.data.status == 'PRESENT' ? entry.data.status : 'ABSENT'},{' '}
          {entry.data.className}, {entry.data.groupName}.
        </span>;
    case HistoryEntryType.AgreementSigned:
      return <>
          <span className=" font-light text-gray-700 dark:text-slate-400">
            Agreement:
          </span>
          <Link to={`${agreementConfig.path}/${entry.data.agreementId}/view`} className="font-semibold dark:text-slate-300">
            {' '}
            {entry.data.label}
          </Link>
        </>;
    default:
      return null;
  }
};
export const NotificationDetail = ({
  item,
  displayCustomer = true,
  displayAvatar = true
}) => {
  _s();
  const [modalOpen, setModalOpen] = useState(false);
  let historyItem;
  let customer;
  let order;
  let administrator;
  if ('order' in item) {
    historyItem = item;
    order = historyItem.order;
    customer = historyItem.order.customer;
  }
  if ('customer' in item) {
    historyItem = item;
    customer = historyItem.customer;
  }
  if ('administrator' in item) {
    administrator = item.administrator;
  }
  return <div className="flex items-start">
      {customer && displayAvatar ? <Avatar source={customer.customFields?.image?.preview} type="circular" size="xs" altContent={`${customer.firstName} ${customer.lastName}`} className="mr-2" /> : displayAvatar ? <div className="w-11" /> : null}
      <p className="truncate text-sm">
        {order ? <>
            <span className="block font-light text-gray-700 dark:text-slate-400">
              Order:{' '}
              <Link to={`${orderConfig.path}/${order.id}`} className="font-semibold dark:text-slate-300">
                {order.code}
              </Link>
            </span>
          </> : null}
        {displayCustomer && customer ? <>
            <span className="block font-light text-gray-700 dark:text-slate-400">
              Customer:{' '}
              <Link to={`${customerConfig.path}/${customer.id}`} className="font-semibold dark:text-slate-300">
                {customer.firstName} {customer.lastName}
              </Link>
            </span>
            <span className="block text-gray-500 dark:text-slate-300">
              {customer.emailAddress}
            </span>
          </> : null}
        {dataTranslate(item)}
      </p>
    </div>;
};
_s(NotificationDetail, "CMfkJY0oHKX43wkRh7WkxSiVV1E=");
_c = NotificationDetail;
export const Notification = ({
  item,
  iconSize = 'small',
  displayAvatar
}) => {
  let administrator;
  if ('administrator' in item) {
    administrator = item.administrator;
  }
  return <li key={item.id} className="w-full relative py-2 px-2">
      <div className="flex items-start gap-x-3 mb-1">
        <NotificationIcon entry={item} iconSize={iconSize} type={NotificationType.Notification} />
        <div className="w-full">
          <div className="flex justify-between mb-0.5">
            <h3 className="mb-1 flex-auto truncate text-base font-medium leading-6 text-gray-400 dark:text-slate-400">
              {notificationTitle(item.type)}
            </h3>
            <time dateTime={item.createdAt} className="flex-none text-xs font-light text-gray-400 dark:text-slate-400">
              {prettyDateFormat(new Date(item.createdAt))}
            </time>
          </div>
          <NotificationDetail item={item} displayAvatar={displayAvatar} />
        </div>
      </div>
    </li>;
};
_c2 = Notification;
var _c, _c2;
$RefreshReg$(_c, "NotificationDetail");
$RefreshReg$(_c2, "Notification");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;